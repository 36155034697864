<template>
  <div>
    <!-- 下级考核 -->
    <el-row type="flex" justify="space-between">
      <div class="top-info">
        <el-button class="fontsize18" icon="el-icon-arrow-left" size="mini" @click="$router.go(-1)">
          返回
        </el-button>
        <div class="fontsize18 mr-10">姓名：{{ params.name }}</div>
        <div class="fontsize18">岗位：{{ params.postName }}</div>
      </div>
      <div class="datePicker">
        <el-date-picker
          v-model="date"
          type="daterange"
          placeholder="选择日期"
          class="datePicker"
          value-format="yyyy-MM-dd"
          @change="getData"
        >
        </el-date-picker>
      </div>
    </el-row>
    <div class="mt-10">
      <el-table
        header-cell-class-name="table-header"
        cell-class-name="row-cell"
        :data="tableList"
        class="table mt20 table-container"
      >
        <el-table-column
          label="序号"
          type="index"
          width="100"
        ></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="岗位工种" prop="varietiesName">
        </el-table-column>
        <el-table-column label="纪律表现" prop="discipline">
          <template slot-scope="scope">
            <span class="color1" v-if="scope.row.discipline == 1">优秀</span>
            <span class="color2" v-if="scope.row.discipline == 2">合格</span>
            <span class="color3" v-if="scope.row.discipline == 3"
              >基本合格</span
            >
            <span class="color4" v-if="scope.row.discipline == 4">不合格</span>
          </template>
        </el-table-column>
        <el-table-column label="管理服从度" prop="manage">
          <template slot-scope="scope">
            <span class="color1" v-if="scope.row.manage == 1">优秀</span>
            <span class="color2" v-if="scope.row.manage == 2">合格</span>
            <span class="color3" v-if="scope.row.manage == 3">基本合格</span>
            <span class="color4" v-if="scope.row.manage == 4">不合格</span>
          </template>
        </el-table-column>
        <el-table-column label="安全文明" prop="safe">
          <template slot-scope="scope">
            <span class="color1" v-if="scope.row.safe == 1">优秀</span>
            <span class="color2" v-if="scope.row.safe == 2">合格</span>
            <span class="color3" v-if="scope.row.safe == 3">基本合格</span>
            <span class="color4" v-if="scope.row.safe == 4">不合格</span>
          </template>
        </el-table-column>
        <el-table-column label="质量" prop="quality">
          <template slot-scope="scope">
            <span class="color1" v-if="scope.row.quality == 1">优秀</span>
            <span class="color2" v-if="scope.row.quality == 2">合格</span>
            <span class="color3" v-if="scope.row.quality == 3">基本合格</span>
            <span class="color4" v-if="scope.row.quality == 4">不合格</span>
          </template>
        </el-table-column>
        <el-table-column label="效率" prop="efficiency">
          <template slot-scope="scope">
            <span class="color1" v-if="scope.row.efficiency == 1">优秀</span>
            <span class="color2" v-if="scope.row.efficiency == 2">合格</span>
            <span class="color3" v-if="scope.row.efficiency == 3"
              >基本合格</span
            >
            <span class="color4" v-if="scope.row.efficiency == 4">不合格</span>
          </template></el-table-column
        >
        <el-table-column label="考核形式" prop="isAutomatic"> </el-table-column>
        <el-table-column label="考核时间" prop="examineDate"> </el-table-column>
        <el-table-column label="工作时长（小时）" prop="dayHours">
        </el-table-column>
        <el-table-column label="详情" prop="name">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="assessment(scope.row)"
              >查看历史考核</el-button
            >
          </template></el-table-column
        >
      </el-table>
      <div class="block mt10 pagetion">
        <el-pagination
          layout="total, prev, pager, next"
          :total="pageData.total"
          :page-size="pageData.pageSize"
          :current-page.sync="pageData.pageNum"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="历史考核"
      :modal-append-to-body="false"
      width="800px"
      :visible.sync="assessVisible"
      :append-to-body="false"
    >
      <div style="max-height: 50vh; overflow-y: scroll">
        <el-form ref="queryFormwoke" :inline="true" label-width="100px">
          <el-form-item label="">
            <div class="flex just-start items-center">
              <span
                v-if="tableData3.length > 0"
                class="renyuansize mr-20 ml-20"
                >{{ tableData3[0].userName }}</span
              >
              <div class="avatar-box mr-10">
                <img
                  v-if="tableData3.length > 0"
                  :src="tableData3[0].userAvatar"
                  alt=""
                />
              </div>
              <el-date-picker
                v-model="timerFormwoke"
                :append-to-body="false"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="changeDetailDate"
              >
              </el-date-picker>
              <el-button
                type="primary"
                @click="selectHistoryExamineDetailListFn"
                >搜索</el-button
              >
              <el-button type="warning" @click="resetQuery1">重置</el-button>
            </div>
          </el-form-item>
          <el-card class="mr-20 ml-20">
            <vxe-table :data="tableData3">
              <vxe-column field="examineDate" width="120" title="考核时间">
              </vxe-column>

              <vxe-column field="discipline" title="纪律表现">
                <template #default="{ row }">
                  <span
                    v-show="row.discipline == item.id"
                    :class="item.id == 4 ? 'red' : 'defaults'"
                    v-for="(item, index) in chieveList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </vxe-column>
              <vxe-column field="manage" title="管理服从">
                <template #default="{ row }">
                  <span
                    v-show="row.manage == item.id"
                    :class="item.id == 4 ? 'red' : 'defaults'"
                    v-for="(item, index) in chieveList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </vxe-column>
              <vxe-column field="safe" title="安全文明">
                <template #default="{ row }">
                  <span
                    v-show="row.safe == item.id"
                    :class="item.id == 4 ? 'red' : 'defaults'"
                    v-for="(item, index) in chieveList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </vxe-column>
              <vxe-column field="quality" title="质量">
                <template #default="{ row }">
                  <span
                    v-show="row.quality == item.id"
                    :class="item.id == 4 ? 'red' : 'defaults'"
                    v-for="(item, index) in chieveList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </vxe-column>
              <vxe-column field="efficiency" title="效率">
                <template #default="{ row }">
                  <span
                    v-show="row.efficiency == item.id"
                    :class="item.id == 4 ? 'red' : 'defaults'"
                    v-for="(item, index) in chieveList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </vxe-column>
            </vxe-table>
            <div class="pagetion">
              <vxe-pager
                background
                size="small"
                :current-page="tablePage3.pageNum"
                :page-size="tablePage3.pageSize"
                :total="tablePage3.totalResult"
                :page-sizes="[10, 20, 100]"
                :layouts="[
                  'PrevPage',
                  'JumpNumber',
                  'NextPage',
                  'FullJump',
                  'Sizes',
                  'Total',
                ]"
                @page-change="handlePageChange3"
              >
              </vxe-pager>
            </div>
          </el-card>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="showRegulationList = false">取 消</el-button> -->
        <el-button type="primary" @click="assessVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import TopHeader from "./components/top-header.vue";
import moment from "moment"
import { getAssessmentDetail, getAssessmentList } from "./api.js";
export default {
  components: {
    // TopHeader,
  },
  data() {
    return {
      showReformDetail: false,
      params: {},
      date: [],
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableList: [],
      tableData3: [],
      assessVisible: false,
      tablePage3: {
        pageSize: 10,
        pageNum: 1,
        totalResult: 0,
      },
      queryFormwoke: {
        startDate: "",
        endDate: "",
      },
      timerFormwoke: [],
      chieveList: [
        {
          name: "优秀",
          id: "1",
        },
        {
          name: "合格",
          id: "2",
        },
        {
          name: "基本合格",
          id: "3",
        },
        {
          name: "不合格",
          id: "4",
        },
      ],
    };
  },
  mounted() {
    this.params = this.$route.query;
    var dates = moment(new Date()).format("yyyy-MM-DD")
    this.date = [dates, dates]; 
    // this.date = [
    //   this.parseTime(new Date(), "{y}-{m}-{d}"),
    //   this.parseTime(new Date(), "{y}-{m}-{d}"),
    // ];
    this.calcHeight();
  },
  methods: {
    getData() {
      getAssessmentList({
        projectUserInfoHistoryId: this.params.projectUserInfoHistoryId,
        startDate: this.date[0],
        endDate: this.date[1],
        ...this.pageData,
      }).then((res) => {
        this.tableList = res.data.rows;
        this.pageData.total = res.data.total;
      });
    },
    changeDetailDate(e) {
      this.queryFormwoke.startDate = e[0];
      this.queryFormwoke.endDate = e[1];
    },
    assessment(row) {
      this.assessmentId = row.projectUserInfoHistoryId;
      this.queryFormwoke = {};
      this.timerFormwoke = [];
      this.assessVisible = true;
      this.selectHistoryExamineDetailListFn();
    },
    handlePageChange3({ currentPage, pageSize }) {
      this.tablePage3.pageNum = currentPage;
      this.tablePage3.pageSize = pageSize;
      this.selectHistoryExamineDetailListFn();
    },

    selectHistoryExamineDetailListFn() {
      getAssessmentDetail({
        projectUserInfoHistoryId: this.assessmentId,
        ...this.tablePage3,
        ...this.queryFormwoke,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData3 = res.data.rows;
          this.tablePage3.totalResult = res.data.total;
        }
      });
    },
    resetQuery1() {
      this.queryFormwoke = {};
      this.timerFormwoke = [];
      this.selectHistoryExamineDetailListFn();
    },
    calcHeight() {
      if (this.pageData.pageNum > 1) {
        return;
      }
      const myElement = document.getElementsByClassName("top-info")[0];
      const rect = myElement.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.bottom - 200;

      this.pageData.pageSize = Math.floor(distanceToBottom / 60);
      this.getData();
    },
    handleResize() {
      this.calcHeight()
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="less" scoped>
.top-info {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.datePicker ::v-deep .el-input__inner,
.datePicker ::v-deep .el-input__inner .el-range-separator {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
.datePicker{
  display: flex;
  justify-content: center;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}
.datePicker ::v-deep .el-range-input {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}

.color1 {
  color: #01f28e;
}

.color2 {
  color: #33d4ff;
}

.color3 {
  color: #fdff00;
}

.color4 {
  color: #ff0000;
}

#mains {
  width: 100%;
  height: 400px;
  background: #fff;
}

.echart-btn {
  margin-right: 50px;
}

.list-left {
  width: 100px;
}

.records {
  width: 150px;
  font-size: 14px;
}

.time-box {
  display: flex;
  justify-content: flex-start;
}

.eq-box {
  width: 40px;
  height: 40px;
  background: #1890ff;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

.time {
  width: 100px;
  color: #8a8a8a;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}

.span1 {
  width: 50px;
  font-weight: 800;
}

.span2 {
  width: 50px;
}

.span3 {
  width: 50px;
}

.top {
}

.pic1,
.pic2 {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

.pic {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

.red {
  color: red;
}

.avatar-box {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}
.mt-10{
  margin-top: 0.1rem;
}
</style>
